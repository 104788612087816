import React from 'react'
import Header from '../../components/Header/Header'
import './Products.css'
import Footer from '../../components/Footer/Footer'

const Products = () => {
  return (
    <>
      <Header />
      <div className="slider-container">
        <div className='banner page_head'>
          <div className="container_section_main">
            <div className='col-md-12 text-center'>
              <h2> Products </h2>
            </div>
          </div>

        </div>

      </div>
      <div className="what-all-you-get">
        <div className="container_section_main">
          <div className="row">
            <div className="col-md-12">
              <table className='table table-bordered'>
                <thead class="thead-dark">
                  <tr>
                    <th colspan="3"  style={{ fontWeight: "700" }}>STEROID</th>

                  </tr>

                </thead>
                <tbody>
                  <tr>
                    <td width="10%" style={{ textAlign: "left", fontWeight: "700" }}>S. No.</td>
                    <td width="55%" style={{ textAlign: "left", fontWeight: "700" }}>Products</td>
                    <td width="35%" style={{ textAlign: "left", fontWeight: "700" }}>Pharmacopoeia</td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">1.</td>
                    <td width="55%" align="left" valign="middle">PREDNISOLONE ACETATE</td>
                    <td width="35%" align="left" valign="middle">IP/BP/USP</td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">2.</td>
                    <td width="55%" align="left" valign="middle">METHYLPREDNISOLONE ACETATE</td>
                    <td width="35%" align="left" valign="middle">IP/BP/USP</td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">3.</td>
                    <td width="55%" align="left" valign="middle">MOMETASONE FUROATE</td>
                    <td width="35%" align="left" valign="middle">IP/BP/USP</td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">4.</td>
                    <td width="55%" align="left" valign="middle">DEXAMETHASONE SODIUM PHOSPHATE</td>
                    <td width="35%" align="left" valign="middle">IP/BP/USP</td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">5.</td>
                    <td width="55%" align="left" valign="middle">BETAMETHASONE SODIUM PHOSPHATE</td>
                    <td width="35%" align="left" valign="middle">IP/BP/USP</td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">6.</td>
                    <td width="55%" align="left" valign="middle">CLOBETASOL PROPIONATE</td>
                    <td width="35%" align="left" valign="middle">IP/BP/USP</td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">7.</td>
                    <td width="55%" align="left" valign="middle">BETAMETHASONE VALERATE</td>
                    <td width="35%" align="left" valign="middle">IP/BP/USP</td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">8.</td>
                    <td width="55%" align="left" valign="middle">BETAMETHASONE DIPROPIONATE</td>
                    <td width="35%" align="left" valign="middle">IP/BP/USP</td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">9.</td>
                    <td width="55%" align="left" valign="middle">BECLOMETHASONE DIPROPIONATE</td>
                    <td width="35%" align="left" valign="middle">IP/BP/USP</td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">10.</td>
                    <td width="55%" align="left" valign="middle">DEFLAZACORT</td>
                    <td width="35%" align="left" valign="middle">I.H.S</td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">11.</td>
                    <td width="55%" align="left" valign="middle">BETAMETHASONE</td>
                    <td width="35%" align="left" valign="middle">IP/BP/USP</td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">12.</td>
                    <td width="55%" align="left" valign="middle">DEXAMETHASONE</td>
                    <td width="35%" align="left" valign="middle">IP/BP/USP</td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">13.</td>
                    <td width="55%" align="left" valign="middle">METHYLPREDNISOLONE</td>
                    <td width="35%" align="left" valign="middle">IP/BP/USP</td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">14.</td>
                    <td width="55%" align="left" valign="middle">DUTASTERIDE</td>
                    <td width="35%" align="left" valign="middle">IP/USP</td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">15.</td>
                    <td width="55%" align="left" valign="middle">PREDNISOLONE</td>
                    <td width="35%" align="left" valign="middle">IP/BP/USP</td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">16.</td>
                    <td width="55%" align="left" valign="middle">PREDNISOLONE SODIUM PHOSPHATE</td>
                    <td width="35%" align="left" valign="middle">IP/BP</td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">17.</td>
                    <td width="55%" align="left" valign="middle">TRIAMCINOLONE ACETONIDE</td>
                    <td width="35%" align="left" valign="middle">IP/BP/USP/EP</td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">18.</td>
                    <td width="55%" align="left" valign="middle">HYDROCORTISONE ACETATE</td>
                    <td width="35%" align="left" valign="middle">IP/BP/USP</td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">19.</td>
                    <td width="55%" align="left" valign="middle">FLUMETHASONE ACETATE</td>
                    <td width="35%" align="left" valign="middle">IP/BP/USP</td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">20.</td>
                    <td width="55%" align="left" valign="middle">FLUTICASONE PROPIONATE</td>
                    <td width="35%" align="left" valign="middle">IP/BP</td>
                  </tr>
                </tbody>


              </table>
            </div>
            <div class="col-md-12 mt-3">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th colspan="3" style={{ fontWeight: "700" }}>Vitamins</th>

                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td width="10%" style={{ textAlign: "left", fontWeight: "700" }}>S. No.</td>

                    <td width="55%" style={{ textAlign: "left", fontWeight: "700" }}>  Products	</td>
                    <td width="35%" style={{ textAlign: "left", fontWeight: "700" }}>Pharmacopoeia</td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">21.</td>
                    <td width="55%" align="left" valign="middle">METHYLCOBALAMIN</td>
                    <td width="35%" align="left" valign="middle">JP/IP</td>
                  </tr>

                  <tr>
                    <td align="left" valign="middle">22.</td>
                    <td width="55%" align="left" valign="middle">CHOLECALCIFEROL (VITAMIN D3)</td>
                    <td width="35%" align="left" valign="middle">IP/USP</td>
                  </tr>

                  <tr>
                    <td align="left" valign="middle">23.</td>
                    <td width="55%" align="left" valign="middle">D-BIOTIN</td>
                    <td width="35%" align="left" valign="middle">BP/USP</td>
                  </tr>

                  <tr>
                    <td align="left" valign="middle">24.</td>
                    <td width="55%" align="left" valign="middle">BENFOTIAMINE</td>
                    <td width="35%" align="left" valign="middle">IH</td>
                  </tr>

                  <tr>
                    <td align="left" valign="middle">25.</td>
                    <td width="55%" align="left" valign="middle">ASCORBIC ACID</td>
                    <td width="35%" align="left" valign="middle">IP/BP/USP</td>
                  </tr>

                  <tr>
                    <td align="left" valign="middle">26.</td>
                    <td width="55%" align="left" valign="middle">THIAMIN HYDROCHLORIDE</td>
                    <td width="35%" align="left" valign="middle">IP</td>
                  </tr>

                  <tr>
                    <td align="left" valign="middle">27.</td>
                    <td width="55%" align="left" valign="middle">LYCOPIN</td>
                    <td width="35%" align="left" valign="middle">IP/BP/USP</td>
                  </tr>



                </tbody>
              </table>

            </div>
            <div className="col-md-12 mt-3">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th colspan="3" style={{ fontWeight: "700" }}>HORMONE</th>

                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td width="10%" style={{ textAlign: "left", fontWeight: "700" }}>S. No.</td>

                    <td width="55%" style={{ textAlign: "left", fontWeight: "700" }}>  Products	</td>
                    <td width="35%" style={{ textAlign: "left", fontWeight: "700" }}>Pharmacopoeia</td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">28.</td>
                    <td width="55%" align="left" valign="middle">NANDROLONE DECANOATE</td>
                    <td width="35%" align="left" valign="middle">IP/USP</td>
                  </tr>

                  <tr>
                    <td align="left" valign="middle">29.</td>
                    <td width="55%" align="left" valign="middle">TESTOSTERONE CYPIONATE</td>
                    <td width="35%" align="left" valign="middle">USP</td>
                  </tr>

                  <tr>
                    <td align="left" valign="middle">30.</td>
                    <td width="55%" align="left" valign="middle">TESTOSTERONE DECANOATE</td>
                    <td width="35%" align="left" valign="middle">BP</td>
                  </tr>

                  <tr>
                    <td align="left" valign="middle">31.</td>
                    <td width="55%" align="left" valign="middle">TESTOSTERONE ISOCAPROATE</td>
                    <td width="35%" align="left" valign="middle">BP</td>
                  </tr>

                  <tr>
                    <td align="left" valign="middle">32.</td>
                    <td width="55%" align="left" valign="middle">TESTOSTERONE PROPIONATE</td>
                    <td width="35%" align="left" valign="middle">IP/BP/USP</td>
                  </tr>

                  <tr>
                    <td align="left" valign="middle">33.</td>
                    <td width="55%" align="left" valign="middle">TESTOSTERONE UNDECANOATE</td>
                    <td width="35%" align="left" valign="middle">IHS</td>
                  </tr>

                  <tr>
                    <td align="left" valign="middle">34.</td>
                    <td width="55%" align="left" valign="middle">TESTOSTERONE</td>
                    <td width="35%" align="left" valign="middle">BP/USP</td>
                  </tr>

                </tbody>
              </table>

            </div>
            <div className="col-md-12 mt-3">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th colspan="3" style={{ fontWeight: "700" }}>ANTIBIOTICS</th>

                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td width="10%" style={{ textAlign: "left", fontWeight: "700" }}>S. No.</td>

                    <td width="55%" style={{ textAlign: "left", fontWeight: "700" }}>  Products	</td>
                    <td width="35%" style={{ textAlign: "left", fontWeight: "700" }}>Pharmacopoeia</td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">35.</td>

                    <td width="55%" align="left" valign="middle">  AZITHROMYCIN DIHYDRATE	</td>
                    <td width="35%" align="left" valign="middle">IP/BP/USP</td>
                  </tr>

                  <tr>
                    <td align="left" valign="middle">36.</td>

                    <td width="55%" align="left" valign="middle">OFLOXACIN	</td>
                    <td width="35%" align="left" valign="middle">IP/USP</td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">37.</td>

                    <td width="55%" align="left" valign="middle">ERYTHROMYCIN ACETATE	</td>
                    <td width="35%" align="left" valign="middle">IP/BP</td>
                  </tr>


                </tbody>
              </table>
            </div>

            <div className="col-md-12 mt-3">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th colspan="3" style={{ fontWeight: "700" }}>NSAID</th>

                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td width="10%" style={{ textAlign: "left", fontWeight: "700" }}>S. No.</td>

                    <td width="55%" style={{ textAlign: "left", fontWeight: "700" }}>  Products	</td>
                    <td width="35%" style={{ textAlign: "left", fontWeight: "700" }}>Pharmacopoeia</td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">38.</td>

                    <td width="55%" align="left" valign="middle">  IBUPROFEN	</td>
                    <td width="35%" align="left" valign="middle">IP/BP/USP</td>
                  </tr>

                  <tr>
                    <td align="left" valign="middle">39.</td>

                    <td width="55%" align="left" valign="middle">THIOCOLCHICOSIDE	</td>
                    <td width="35%" align="left" valign="middle">IP/BP/USP</td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">40.</td>

                    <td width="55%" align="left" valign="middle">ETORICOXIB	</td>
                    <td width="35%" align="left" valign="middle">IP/BP/USP</td>
                  </tr>


                </tbody>
              </table>
            </div>
            <div className="col-md-12 mt-3">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th colspan="3" style={{ fontWeight: "700" }}>ANTIDIABETIC</th>

                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td width="10%" style={{ textAlign: "left", fontWeight: "700" }}>S. No.</td>

                    <td width="55%" style={{ textAlign: "left", fontWeight: "700" }}>  Products	</td>
                    <td width="35%" style={{ textAlign: "left", fontWeight: "700" }}>Pharmacopoeia</td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">41.</td>

                    <td width="55%" align="left" valign="middle">  LINAGLIPTIN	</td>
                    <td width="35%" align="left" valign="middle">IP/BP/USP</td>
                  </tr>

                  <tr>
                    <td align="left" valign="middle">42.</td>

                    <td width="55%" align="left" valign="middle">DAPAGLIFLOZIN	</td>
                    <td width="35%" align="left" valign="middle">IP/BP/USP</td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">43.</td>

                    <td width="55%" align="left" valign="middle">VIDAGLIPTIN	</td>
                    <td width="35%" align="left" valign="middle">IP/BP/USP</td>
                  </tr>


                </tbody>
              </table>
            </div>
            <div className="col-md-12 mt-3">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th colspan="3" style={{ fontWeight: "700" }}>ANTI HYPERTENSIVE</th>

                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td width="10%" style={{ textAlign: "left", fontWeight: "700" }}>S. No.</td>

                    <td width="55%" style={{ textAlign: "left", fontWeight: "700" }}>  Products	</td>
                    <td width="35%" style={{ textAlign: "left", fontWeight: "700" }}>Pharmacopoeia</td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">44.</td>

                    <td width="55%" align="left" valign="middle">  TELMISARTAN	</td>
                    <td width="35%" align="left" valign="middle">IP/BP/USP</td>
                  </tr>




                </tbody>
              </table>
            </div>
            <div className="col-md-12 mt-3">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th colspan="3" style={{ fontWeight: "700" }}>PPI INHIBITOR</th>

                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td width="10%" style={{ textAlign: "left", fontWeight: "700" }}>S. No.</td>

                    <td width="55%" style={{ textAlign: "left", fontWeight: "700" }}>  Products	</td>
                    <td width="35%" style={{ textAlign: "left", fontWeight: "700" }}>Pharmacopoeia</td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">45.</td>

                    <td width="55%" align="left" valign="middle">  RABEPRAZOLE	</td>
                    <td width="35%" align="left" valign="middle">IP/BP/USP</td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">46.</td>

                    <td width="55%" align="left" valign="middle">  PANTOPRAZOLE SODIUM	</td>
                    <td width="35%" align="left" valign="middle">IP/BP/USP</td>
                  </tr>




                </tbody>
              </table>
            </div>
            <div className="col-md-12 mt-3">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th colspan="3" style={{ fontWeight: "700" }}>BILIARY AGENTS</th>

                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td width="10%" style={{ textAlign: "left", fontWeight: "700" }}>S. No.</td>

                    <td width="55%" style={{ textAlign: "left", fontWeight: "700" }}>  Products	</td>
                    <td width="35%" style={{ textAlign: "left", fontWeight: "700" }}>Pharmacopoeia</td>
                  </tr>
                  <tr>
                    <td align="left" valign="middle">47.</td>

                    <td width="55%" align="left" valign="middle">  URSODEOXYCHOLIC ACID (UDC)	</td>
                    <td width="35%" align="left" valign="middle">IP/BP/USP</td>
                  </tr>



                </tbody>
              </table>
            </div>




          </div>
          <p class=" mt4"><strong>Disclaimer:</strong> API / Substances covered by patent are not offered to the countries where the patent law is in force. However, the final responsibility lies with the customer.</p>
        </div>
      </div>
      <Footer />


    </>
  )
}

export default Products