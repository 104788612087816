import React from 'react'
import Header from '../../components/Header/Header'
import environment from '../../assets/images/environment-1.jpg'
import environment1 from '../../assets/images/environment.jpg'
import './CSR.css'
import Footer from '../../components/Footer/Footer'

const CSR = () => {
  return (

<>
<Header/>
<div className="slider-container">
  <div className='banner page_head'>
    <div className="container_section_main">
        <div className='col-md-12 text-center'>
        <h2> CSR </h2>
        </div>
    </div>

  </div>

</div>
<div className="about-us">
  <div className="container_section_main">
    <div className="row">
      <div className='col-lg-6 col-md-6'>
        <div className="about-left">
        <span>Environment</span>
        <p>We at Unicon are most committed to environment Protection even beyond statutory norms. We consider environment protection our social responsibility. The company has cleared Environmental Impact Assessment (EIA) by The Ministry of Environment and Forests, Government of India.</p>
     <div className='about-image environment'>
      <img src={environment} alt="" />
      </div>  
        </div>
      </div>
      <div className='col-lg-6 col-md-6'>
        <div className='about-right mb-30'>
          <div className="title">
          <h2>CSR</h2>
          <span>CSR</span>
          </div>
          <div className="about-image">
            <img src={environment1} alt="" />
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<Footer/>

</>
)
}

export default CSR