import React from 'react'
import './Certification.css'
import slick1 from '../../assets/images/slick1.jpg'
import slick2 from '../../assets/images/slick2.jpg'
import slick3 from '../../assets/images/slick3.jpg'
import slick4 from '../../assets/images/slick4.jpg'
import slick5 from '../../assets/images/slick5.jpg'
import slick6 from '../../assets/images/slick6.jpg'
import slick7 from '../../assets/images/slick7.jpg'
import slick8 from '../../assets/images/slick8.jpg'
import slick9 from '../../assets/images/slick9.jpg'
import slick10 from '../../assets/images/slick10.jpg'
import Slider from 'react-slick'

const Certification = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
    return (
        <>
            <div className="our-clients">
                <div className="container_section_main">
                    <div className="row">
                        <div className="col-md-12">
                            <h3>Our Certifications</h3>
                        </div>
                        <div className='col-xs-12 col-md-12 pd-tp1'>
                            <div className="slider-container-cert">
                                <Slider {...settings}>
                                    <div>
                                        <img src={slick1} alt="" />
                                    </div>
                                    <div>
                                    <img src={slick2} alt="" />

                                    </div>
                                    <div>
                                    <img src={slick3} alt="" />

                                    </div>
                                    <div>
                                    <img src={slick4} alt="" />

                                    </div>
                                    <div>
                                    <img src={slick5} alt="" />

                                    </div>
                                    <div>
                                    <img src={slick6} alt="" />

                                    </div>
                                    <div>
                                    <img src={slick7} alt="" />
                                    </div>
                                    <div>
                                    <img src={slick8} alt="" />

                                    </div>
                                    <div>
                                    <img src={slick9} alt="" />

                                    </div>
                                    <div>
                                    <img src={slick10} alt="" />

                                    </div>
                                </Slider>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Certification