import React from 'react'
import Header from '../../components/Header/Header'

import { IoCallSharp } from "react-icons/io5";
import { BiWorld } from "react-icons/bi";
import { FaLocationDot } from "react-icons/fa6";

import './ContactUs.css'
import Footer from '../../components/Footer/Footer';
const ContactUs = () => {
  return (

    <>
      <Header />
      <div className="slider-container">
        <div className='banner-contact page_head'>
          <div className="container_section_main">
            <div className='col-md-12 text-center'>
              <h2> Contact Us </h2>
            </div>
          </div>

        </div>

      </div>
      <div className="contact-us">
        <div className="container_section_main">
          <div className="row">
            <div className='col-lg-5 col-md-5 col-sm-6 table_col no-padding'>
              <div className="connect-us">
                <h2>Connect With us</h2>
                <div className='contact-address text-left'>
                  <div className="single-address">
                    <div className='icon-out-div'>

                      <IoCallSharp className='icons_react_con' />
                    </div>
                    <div>
                      <p>Contact Number:</p>
                      <p>+91-9813057496</p>
                      <p>+91-8286666602</p>
                    </div>

                  </div>

                </div>
                <div className='contact-address text-left'>
                  <div className="single-address">
                    <div className='icon-out-div'>

                      <BiWorld className='icons_react_con' />
                    </div>
                    <div>
                    <p>Email:</p>

                      <p>info@uniconLifesciences.com</p>
                      <p>sales@uniconLifesciences.com</p>
                    </div>


                  </div>

                </div>
                <div className='contact-address text-left'>
                  <div className="single-address">
                    <div className='icon-out-div'>
                      <FaLocationDot className='icons_react_con' />

                    </div>
                    <div>
                      <p>Head Office:</p>
                      <p>395, Opposite Panipat Refinery Township Gate No 1, Dadlana (Panipat)</p>
                    </div>


                  </div>

                </div>
                <div className='contact-address text-left'>
                  <div className="single-address">
                    <div className='icon-out-div'>
                      <FaLocationDot className='icons_react_con' />

                    </div>

                    <div>
                      <p>Work Address:</p>
                      <p>Village Pabana Hashanpur, District Karnal (Haryana)</p>

                    </div>

                  </div>

                </div>

              </div>

            </div>
            <div className='col-lg-7 col-md-7 col-sm-6 table_col no-padding z-ind'>
              <div className="form1">
                <h2>Enquire Now</h2>
                <div className='col-md-12 no-padding'>
                  <input class="input" id="contact_name" name="name" required="required" placeholder="Your Name" type="text" className='input-con-div' />

                </div>
                <div className='col-md-12 no-padding'>
                  <input class="input" id="contact_email" name="email" required="required" placeholder="E-mail Address" type="email" className='input-con-div' />

                </div>
                <div className='col-md-12 no-padding'>
                  <input class="input" id="contact_phone" name="phone" required="required" placeholder="Phone" type="text" className='input-con-div' />

                </div>
                <div className='col-md-12 no-padding'>
                  <textarea id="message" name="message" rows="8" required="required" placeholder="Message" className='input-con-div-tex'></textarea>
                </div>
                <div class="col-md-12 no-padding">
                  <button name="submit" class="know_more_btn ">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
<Footer/>


    </>
  )
}

export default ContactUs