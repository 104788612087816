import './App.css';
import Home from './pages/Home/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import AboutUs from './pages/AboutUs/AboutUs';
import Products from './pages/Products/Products';
import QCAssurance from './pages/QC/QCAssurance';
import RD from './pages/R&D/R&D';
import CSR from './pages/CSR/CSR';
import NewsEvents from './pages/NewsEvents/NewsEvents';
import ContactUs from './pages/ContactUs/ContactUs';



function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/aboutus' element={<AboutUs />} />
        <Route path='/products' element={<Products />} />
        <Route path='/qualitycontrol' element={<QCAssurance />} />
        <Route path='/rd' element={<RD />} />
        <Route path='/csr' element={<CSR />} />
        <Route path='/newsevents' element={<NewsEvents />} />
        <Route path='/contactus' element={<ContactUs />} />
       
      </Routes>
    </BrowserRouter>
  );
}

export default App;
