import React from 'react'
import Header from '../../components/Header/Header'

const NewsEvents = () => {
  return (

<>
<Header/>
<div className="slider-container">
  <div className='banner page_head'>
    <div className="container_section_main">
        <div className='col-md-12 text-center'>
        <h2> News & Events </h2>
        </div>
    </div>

  </div>

</div>

</>
)
}

export default NewsEvents