import React from 'react'
import './WhyWould.css'
import whyWould1 from '../../assets/images/why-would.jpg'
const WhyWould = () => {
    return (
        <>
            <div className="why-would">
                <div className="container_section_main">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 tb-ce">
                            <img src={whyWould1} alt="" />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 tb-ce">
                            <div className="why-would-txt">
                                <h2>Why Would you take our services</h2>
                                <p>The company has also been awarded the ISO 9001-2015 Management system Certificate towards manufacturing of quality products consistently This reflects the companies commitments towards Quality and Standards.</p>
                                <div className="exclusives-services">
                                    <ul>
                                        <li><span>01</span>
                                            <div className='exclu-sec'>
                                                <h3> Exclusives Services</h3>
                                                <p>The company has also been awarded the ISO 9001-2015 Management system</p>
                                            </div>
                                        </li>
                                        <li><span>02</span>
                                            <div className='exclu-sec'>
                                                <h3> Expert Team Engineer</h3>
                                                <p>The company has also been awarded the ISO 9001-2015 Management system

</p>
                                            </div>
                                        </li>
                                        <li><span>03</span>
                                            <div className='exclu-sec'>
                                                <h3> Advance Technology</h3>
                                                <p>The company has also been awarded the ISO 9001-2015 Management system</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default WhyWould