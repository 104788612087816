import React from 'react'
import Header from '../../components/Header/Header'
import './QCAssurance.css'
import QCimage from '../../assets/images/QC.jpg'
import Footer from '../../components/Footer/Footer'

const QCAssurance = () => {
  return (

<>
<Header/>
<div className="slider-container">
  <div className='banner page_head'>
    <div className="container_section_main">
        <div className='col-md-12 text-center'>
        <h2> QUALITY CONTROL & ASSURANCE </h2>
        </div>
    </div>

  </div>

</div>
<div className="about-us">
  <div className="container_section_main">
    <div className="row">
      <div className='col-lg-6 col-md-6'>
        <div className="about-left">
        <span>WELCOME</span>
        <p>Unicon Quality Control (QC) and Quality Assurance (QA) departments are backed by a strong team of well-
qualified and experienced professionals with an excellent exposure to modern analytical techniques and
regulatory procedures. The company follows a very stringent QA policy to ensure zero to minimal deviation in
results during commercial-scale production. In order to implement that, complete monitoring &amp; testing is done
during each and every stage of the manufacturing process for all our products. The strict screening policy
							ensures consistent quality sans aberrations.</p>
              <p>The Quality control department is equipped with all the modern 21 CFR analytical instruments like:</p>
              <ul class="clients-li">
						<li>Head space GC (Perkin Elmer)</li>
						<li>UV- Visible Spectrophotometers (Shimadzu &amp; Perkin Elmer)</li>
						<li>FT-IR (Perkin Elmer)</li>
						<li>Particle Size Analyzer (Sympatec)</li>
						<li>Purified water system (Millipore)</li>
						<li>And a series of HPLCs (Shimadzu)</li>
						</ul>
            <p>The laboratories are also equipped with several other instruments like:</p>
            <div className='ul-qc-div'>
            <ul class="clients-li wid-50">
						<li>Karl-Fischer titrators</li>
						<li>Weighing balances</li>
						<li>Polarimeter</li>
						<li>Melting Temperature apparatus</li>
						<li>TLC UV lamps</li>
						
						</ul>
            <ul class="clients-li wid-50 pl-10">
						
						<li>Drying ovens</li>
						<li>Stability Chambers</li>
						<li>Freeze Dryers, Incubation chambers</li>
						<li>Sonicators</li>
						<li>Fuming hoods</li>
						</ul>

            </div>
            <p>As a testament to the continuous efforts of our QC-QA team, Unicon Lifesciences has been awarded
with the prestigious Good Laboratory Practices (GLP) certification by the Food and Drug Authority (FDA),
Haryana.</p>
        </div>

      </div>
      <div className='col-lg-6 col-md-6'>
        
      <div className='about-right mb-30'>
        <div className="title">
        <h2>QC &amp; QA</h2>
        <span>QC &amp; QA</span>
        
        </div>
        <div className="about-image">
          <img src={QCimage} alt="" />
        </div>
      </div>
    </div>
      </div>
  </div>
</div>
<Footer/>


</>
)
}

export default QCAssurance