import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
<>
<div className="footer-bootom">
    <div className="container_section_main">
        <div className="row">
            <div className='col-md-6 col-sm-6'>
            <p>© Unicon Lifesciences - All Rights Reserved</p>
            </div>
        </div>
    </div>
</div>
</>
)
}

export default Footer