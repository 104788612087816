import React from 'react'
import './Banner.css'
import Slider from "react-slick";
import image1 from '../../assets/images/image1.jpg'
import image2 from '../../assets/images/image2.jpg'
import image3 from '../../assets/images/image3.jpg'
import image4 from '../../assets/images/image4.jpg'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdArrowBackIos } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";


const Banner = () => {
    const settings = {
        dots: true,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        waitForAnimate: false,
        nextArrow: <MdArrowForwardIos />,
    prevArrow: <MdArrowBackIos />
      };
  return (
        <div className="slider-container">
      <Slider {...settings} className='slider-banner'>
        <div>
          <img className='banner-img' src={image1} />
        </div>
        <div>
          <img className='banner-img' src={image2} />
        </div>
        <div>
          <img className='banner-img' src={image3} />
        </div>
        <div>
          <img className='banner-img' src={image4} />
        </div>
      </Slider>
    </div>
  )
}

export default Banner