import React from 'react'
import Header from '../../components/Header/Header'
import Banner from '../../components/Banner/Banner'
import Detail from '../../components/Detail/Detail'
import Research from '../../components/Reasearch/Research'
import WhyWould from '../../components/WhyWould/WhyWould'
import Certification from '../../components/Certification/Certification'
import Footer from '../../components/Footer/Footer'

const Home = () => {
  return (
    <div>
      <Header/>
      <Banner/>

      <Detail/>
      <Research/>
      <WhyWould/>
      <Certification/>
      <Footer/>
      {/* <Banner/> */}
      
    </div>
  )
}

export default Home
