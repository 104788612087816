import React from 'react'
import Header from '../../components/Header/Header'
import rd from '../../assets/images/r-d.jpg'
import Footer from '../../components/Footer/Footer'

const RD = () => {
  return (
    <>
    
<Header/>
<div className="slider-container">
  <div className='banner page_head'>
    <div className="container_section_main">
        <div className='col-md-12 text-center'>
        <h2> R&D </h2>
        </div>
    </div>

  </div>

</div>
<div className="about-us">
  <div className="container_section_main">
    <div className="row">
      <div className="col-lg-6 col-md-6">
        <div className="about-left">
        <span>WELCOME</span>
        <p>Unicon firmly believes that Research and Development are the backbone for the magnification of any organization. The company has a state-of-the-art Product Development Laboratory (PDL), backed by a team of highly dedicated and motivated researchers who work diligently towards development of new molecules and also improving upon the synthesis of our current range of molecules, with the goal of improving efficiency in terms of time-consumption, yields and implementation of the principles of atom economy and green chemistry.</p>
        <p>The team follows a logical mechanism-based approach towards product development. In order to develop a substrate, rigorous screening is done in terms of solvents, reagents, toxicity, safety, temperature and pressure. Next, other important parameters like reaction time, utility consumption, and cost of raw materials is taken into account. It is only after the thorough optimization of all the above-mentioned parameters before the team commences the validation and commercialization process. The R&amp;D personnelattends several Pharma and Chemistry related conferences, seminars and workshops at regular intervals to keep themselves updated with the latest developments in laboratory techniques, scientific methodology, upcoming molecules and technical developments.</p>
        </div>
      </div>
      <div className="col-lg-6 col-md-6">
        <div className='about-right mb-30'>
          <div className="title">
          <h2>R&amp;D</h2>
          <span>R&amp;D</span>
          </div>
          <div className="about-image">
            <img src={rd} alt="" />
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<Footer/>

</>
  )
}

export default RD