import React from 'react'
import './Research.css'
import research1 from '../../assets/images/research1.jpg'
import research2 from '../../assets/images/reasearch2.jpg'
import research3 from '../../assets/images/research3.jpg'
import icon1 from '../../assets/images/icon1.png'
import icon2 from '../../assets/images/icon2.png'
import icon3 from '../../assets/images/icon3.png'

const Research = () => {
    return (
        <>
            <div className='inner-color tb'>
                <div className="container_section_main">
                    <div className="row">
                        <div className='col-lg-4 col-md-4 col-sm-4 tb-ce'>
                            <div className="front-thumbnail">
                                <div className='box-pic'>
                                    <img src={research1} alt="" />
                                </div>
                                <div className="rounded-circle">
                                    <div className='center-circle-icon'>

                                        <img src={icon1} alt="" />
                                    </div>
                                </div>
                                <div className='figure-caption'>
                                    <h4><a href="r-d.php">Research &amp; Development</a></h4>
                                    <p>Research and Development is an essential part and thrust towards innovation, projects planning &amp; execution in any organization.</p>
                                </div>
                            </div>

                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-4 tb-ce'>
                            <div className="front-thumbnail">
                                <div className='box-pic'>
                                    <img src={research2} alt="" />
                                </div>
                                <div className="rounded-circle">
                                    <div className='center-circle-icon'>

                                        <img src={icon2} alt="" />
                                    </div>
                                </div>
                                <div className='figure-caption'>
                                    <h4><a href="r-d.php">ISO Certification</a></h4>
                                    <p>The company has also been awarded the ISO 9001-2015 Management system Certificate towards manufacturing of quality products consistently This reflects the companies commitments towards Quality and Standards.</p>    </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-4 tb-ce'>

                            <div className="front-thumbnail">
                                <div className='box-pic'>
                                    <img src={research3} alt="" />
                                </div>
                                <div className="rounded-circle">
                                    <div className='center-circle-icon'>

                                        <img src={icon3} alt="" />
                                    </div>
                                </div>
                                <div className='figure-caption'>
                                    <h4><a href="r-d.php">Infrastructure</a></h4>

                                    <p>State of the art manufacturing facility at Pabana Hashanpur near Panipat (India) covering huge area of land with number of SS Glass Lined Reactors.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>
    )
}

export default Research