import React from 'react'
import './Detail.css'
import about1 from '../../assets/images/about1.jpg'
import { useNavigate } from 'react-router-dom'

const Detail = () => {
    const navigate=useNavigate();
  return (
   <>
   <div className="container_section_main">
    <div className="row">
            <div className="col-md-7">
                <div className="about-content">
                <h3>Welcome to <span>Unicon Lifesciences</span></h3>
                <p>Unicon Lifesciences is a research and technology-based pharmaceutical company with specialization in the production of Corticosteroids, APIs and Vitamins having presence in international as well as domestic market. Facilities are as per international regulations and capable of very large scale production.</p>
                <p>We invite global formulation manufacturers and bulk drug trading/distribution community to have a profitable relationship.</p>
                <button onClick={()=>navigate("/aboutus")}>Know more</button>
                </div>
            </div>
            <div className="col-md-5">
                <div className="about-img-box">
                    <img src={about1} alt="" />
                </div>
            </div>
        </div>
   </div>
   </>
  )
}

export default Detail