import React from 'react'
import './Header.css'
import { IoCallSharp } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import Banner from '../Banner/Banner';
import { useNavigate } from 'react-router-dom';

const Header = () => {
   const navigate = useNavigate();
   return (
      <div>
         <div className="header-first">

         </div>
         <div className="header-top">
            <div className="container_section_main">
               <div className="row">
                  <div class="col-lg-6 col-md-6 col-sm-5 col-xs-12">
                     <div class="logo">
                        <p>Unicon Lifesciences</p>

                     </div>
                  </div>
                  <div className="col-lg-6 d-flex">
                     <div className='header-right-info '>

                        <div className="phone-div">
                           <IoCallSharp className='icons_react' />
                           <div>
                              <p>Call us 9:00am - 6:00pm</p>
                              <p> +91-9813057496</p>
                           </div>
                        </div>
                        <div className="email-div">
                           <MdEmail className='icons_react' />
                           <div>
                              <p>Email us</p>

                              <p>info@uniconLifesciences.com</p>
                           </div>


                        </div>
                     </div>
                  </div>
               </div>
            </div>

         </div>
         {/* bottom */}
         <div className="header-bottom">
            <div className="container_section_main">
               <div className="row">
                  <div className='col-md-10 col-sm-10'>
                     <nav className="navbar navbar-default navbar-sec-sec">
                        <div id="navbar" class="navbar-collapse collapse">

                           <ul class="nav navbar-nav ul-nav-sec">
                              <li><a onClick={() => navigate("/")}>Home</a></li>
                              <li><a onClick={() => navigate("/aboutus")}>About us</a></li>
                              <li><a onClick={() => navigate("/products")}>Products</a></li>
                              <li><a onClick={() => navigate("/qualitycontrol")}>Quality Control &amp; Assurance</a></li>
                              <li><a onClick={() => navigate("/rd")}>R&amp;D</a></li>
                              <li><a onClick={() => navigate("/csr")}>CSR</a></li>
                              <li><a onClick={() => navigate("/newsevents")}>News &amp; Events</a></li>
                              <li><a onClick={() => navigate("/contactus")}>Contact us</a></li>

                           </ul>




                        </div>

                     </nav>

                  </div>
                  <div className='col-md-2 col-sm-2'>
                     <div className='right-side-box'>
                        <div className='rqa-btn' onClick={()=>navigate("/contactus")}>
                           <span class="inner">Get A Quote </span>

                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default Header