import React from 'react'
import Header from '../../components/Header/Header'
import './AboutUs.css'
import about from '../../assets/images/about.jpg'
import Footer from '../../components/Footer/Footer'

const AboutUs = () => {
  return (
    <>
      <Header />
      <div className="slider-container">
        <div className='banner page_head'>
          <div className="container_section_main">
            <div className='col-md-12 text-center'>
              <h2> About Us </h2>
            </div>
          </div>

        </div>

      </div>
      <div className="about-us">
        <div className="container_section_main">
          <div className='row '>
            <div className='col-lg-6 col-md-6'>
              <div className="about-left">
                <span>WELCOME</span>
                <h2>Unicon Lifesciences</h2>
                <p>Unicon Lifesciences is one of the leading manufacturers and exporters of Corticosteroids, APIs and Vitamins from Pabana, India, with a strong global presence in over 50 countries. Our motto is simple
                  <strong>“Premium Quality at Economical Prices.”</strong> We, at Unicon Lifesciences, believe in delivering the highest quality products at competitive prices while demonstrating highest possible ethical standards and our endeavor to preserve the environment.</p>

                <p>The four pillars of our company- Production, QC, QA and R&amp;D departments are all housed in a State-
                  of-the-art manufacturing facility in Pabana Hashanpur near Panipat. The facility is spread over a large area of
                  land and has been fitted with the latest range of equipment, in compliance with 21 CFR, to ensure the best
                  quality products following a very stringent screening policy. The facility has been laid down as per the latest
                  international standards with respect to the area specifications, documentation, production areas, Quality
                  Control and Quality Assurance protocols, purified water supply, ventilation, environmental protection, quality
                  and all the other principal parameters.</p>
                <p>The company has been accredited by several institutions like </p>
                <div className='ul-about-div'>
                  <ul class="clients-li wid-50">
                    <li>IAF</li>
                    <li>ANAB</li>
                    <li>BSI (ISO 9001:2008)</li>
                    <li>ISO 9001:2015</li>
                    <li>ISO 14001:2015</li>
                  </ul>
                  <ul class="clients-li wid-50">
                    <li>D&amp;B </li>
                    <li>Halal India</li>
                    <li>Holds cGMP</li>
                    <li>GLP certifications by FDA, Haryana.</li>
                    <br />
                  </ul>
                </div>
                <p>The company has been recognized as a Star Export House by the Government of India and has been given AEO
                  certification by the Indian Customs Authority. Right from the inception, Unicon Lifesciences has laid a strong
                  emphasis on in-house Research &amp; Development, backed by a team of highly motivated research professionals
                  and technical personnel. Our quality control department has ultra-modern analytical instruments and is
                  supported by a strong team of well qualified and experienced professionals with excellent exposure to
                  contemporary analytical techniques. We are currently manufacturing over 40 products and our R&amp;D team is
                  working tirelessly to diversify our product profile even further.</p>
              </div>

            </div>
            <div className='col-lg-6 col-md-6'>
              <div className='about-right mb-30'>
                <div className="title">
                  <h2>About Us</h2>
                  <span>About Us</span>
                </div>
                <div className="about-image">
                  <img src={about} alt="" />
                </div>

              </div>

            </div>

          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default AboutUs